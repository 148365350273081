import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './app.vue'
import router from './router'
import store from './store'
import * as storage from './storage'

import { mapState } from 'vuex'
import { ACCESS_TOKEN_KEY, getAccessToken } from './auth'
import '@/plugins/base-components'
import '@/plugins/print'
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)
import VS2 from 'vue-script2'
Vue.use(VS2)

import VueEditor from '@/components/vue-md-editor'
Vue.use(VueEditor)

import numbro from 'numbro'

import i18n from './utils/vue-i18n'
import ja from './ja'
import { jaNumbro } from './ja'
numbro.registerLanguage(jaNumbro)

import formatDate from './utils/format-date'
import formatDateRelative from './utils/format-date-relative'
import formatCurrency from './utils/format-currency'
import capitalize from './utils/capitalize'

// https://ency.now.sh/vuency/installation
import Vuency from 'vuency'
Vue.use(Vuency)

import KMapkit, { setMapOptions } from 'vue-mapkit'
Vue.use(KMapkit, { language: 'ja' })

import VueMatomo from 'vue-matomo'
if (import.meta.env.VITE_ENV != 'local') {
    Vue.use(VueMatomo, {
        // Configure your matomo server and site by providing
        host: 'https://a.kinchaku.com',
        siteId: import.meta.env.VITE_ENV == 'production' ? 2 : 1,

        // Overrides the autogenerated tracker endpoint entirely
        // Default: undefined
        // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

        // Overrides the autogenerated tracker script path entirely
        // Default: undefined
        // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

        // Enables automatically registering pageviews on the router
        router,

        // Enables link tracking on regular links. Note that this won't
        // work for routing links (ie. internal Vue router links)
        // Default: true
        enableLinkTracking: true,

        // Require consent before sending tracking information to matomo
        // Default: false
        requireConsent: true,

        // Whether to track the initial page view
        // Default: true
        trackInitialView: true,

        // Run Matomo without cookies
        // Default: false
        disableCookies: false,

        // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
        // Default: false
        enableHeartBeatTimer: true,

        // Set the heartbeat timer interval
        // Default: 15
        heartBeatTimerInterval: 15,

        // Whether or not to log debug information
        // Default: false
        debug: false,

        // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
        // Default: undefined
        userId: undefined,

        // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
        // Default: undefined, example '*.example.com'
        cookieDomain: '*.kinchaku.com',

        // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
        // Default: undefined, example: '*.example.com'
        domains: '*.kinchaku.com',

        preInitActions: [],
    })
}

import Maska from 'maska'
Vue.use(Maska)

import 'typeface-roboto-mono'

Vue.config.productionTip = import.meta.env.DEV

import VueEcho from 'vue-echo'
import Pusher from 'pusher-js'
window.Pusher = Pusher
import { baseURL } from '@/api'
if (import.meta.env.VITE_ENV !== 'local') {
    const options = {
        broadcaster: 'pusher',
        disableStats: true,
        key: import.meta.env.VITE_PUSHER_ID,
        cluster: 'ap3',
        forceTLS: true,
    }
    Vue.use(VueEcho, {
        ...options,
        client: new Pusher(import.meta.env.VITE_PUSHER_ID, {
            ...options,
            channelAuthorization: {
                endpoint: baseURL + '/ws/auth',
                headersProvider: () => {
                    return { 'X-Auth-Token': getAccessToken() }
                },
            },
        }),
    })
    window.Echo = Vue.prototype.$echo
}

if (import.meta.env.VITE_ENV === 'local') {
    Vue.config.errorHandler = function (error, vm, info) {
        // eslint-disable-next-line
        console.error(error, vm, info)
    }
}

import * as Sentry from '@sentry/vue'

Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
    release: 'kinchaku-frontend@' + import.meta.env.VITE_TAG || 'unknown',
})

function replaceLastChild(el, str) {
    if (!el) return false
    if (el.children.length) return replaceLastChild(el.children[0], str)
    el.innerText = str
    return true
}

const app = new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    data() {
        return {
            enDateLocale: {},
            lang: 'ja',
            channel: null,
            proCredits: 10,
            standardCredits: 20,
            standardMonth: 0,
            proPercent: 0.3,
            businessPercent: 0.6,
            standardPercent: 0.9,
            venuePrice: 6600,
            mailerPrice: 1000,
            stampsPrice: 1000,
            pointsPrice: 10000,
        }
    },

    watch: {
        is_logged_in(value) {
            if (import.meta.env.VITE_ENV === 'local') return
            if (value) {
                // this.channel = `App.User.${this.user.id}`
                this.channel = this.$echo.join('app')
                window._paq && window._paq.push(['setUserId', value])
                // this.$echo.private(this.channel).notification(notification => {
                //     console.log(notification)
                // })

                // this.channel.here(users => {
                //     console.log(users)
                // })
            } else {
                this.$echo.leaveChannel(this.channel)
            }
        },
    },

    computed: {
        ...mapState({
            user: state => state.account.user,
            is_logged_in: state => state.account.user.id,
        }),
        dev() {
            return import.meta.env.DEV
        },
        origin_user() {
            return this.user.origin ? this.user.origin : this.user
        },

        /**
         * Return true if user's language is english.
         * @return {boolean}
         */
        is_english() {
            return this.lang === 'en'
        },

        is_japanese() {
            return this.lang === 'ja'
        },

        is_russian() {
            return this.lang === 'ru'
        },

        locale() {
            switch (this.lang) {
                case 'ja':
                    return 'ja-jp'
                case 'ru':
                    return 'ru-ru'
                default:
                    return 'en-us'
            }
        },

        is_dark_mode() {
            return false //window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        },

        origin() {
            return import.meta.env.VITE_BACKEND_URI
        },

        custom_domain() {
            const domain = this.user?.settings?.custom_domain

            return domain ? `https://${domain}` : undefined
        },

        storefront() {
            return this.custom_domain || import.meta.env.VITE_STOREFRONT_URI || this.origin
        },
    },

    created() {
        this.checkRouteSlashes()
        this.addTokenHook()
        i18n.init({ ja })

        router.onReady(r => {
            if (r && r.path.includes('/en/')) {
                this.setLanguage('en')
            } else if (this.user && this.user.language) {
                this.setLanguage(this.user.language)
            }
        })
    },

    mounted() {
        this.setLanguage(storage.local.getItem('app.language') || 'ja')
        storage.local.setItem('app.language', this.lang)
        Vue.mixin({
            methods: {
                $t(key, replacements = {}) {
                    return i18n.translate(this.$root.lang, key, replacements)
                },
                $c(v) {
                    return capitalize(v)
                },
                $cu(v, c) {
                    return formatCurrency(v, c)
                },
                $d(v, f) {
                    return formatDate(v, f)
                },
                $su(s1, s2) {
                    return String(s1) + String(s2)
                },
                $rd(v) {
                    return formatDateRelative(v, this.lang)
                },
            },
        })
        Vue.filter('translate', (key, replacements = {}) => {
            return i18n.translate(this.lang, key, replacements)
        })
        Vue.directive('translate', (el, binding) => {
            var translated_substrings = i18n
                .translate(this.lang, binding.value.key, binding.value.replace)
                .split('|')

            var children = el.children

            for (var i = 0; i < children.length; i++) {
                if (translated_substrings[i]) {
                    replaceLastChild(children[i], translated_substrings[i])
                }
            }
        })
    },

    methods: {
        _handleError(error) {
            if (error.response) {
                if (error.response.status == 401) {
                    this._clearSession()
                }
            } else if (error.request) {
                // console.log(error.request)
            } else if (error.status == 401) {
                this._clearSession()
            } else {
                // console.log('Error', error)
            }
        },

        localeLink(link) {
            return (this.lang == 'en' ? '/en' : '') + link
        },

        redirect(path) {
            router.push(this.localeLink(path))
        },

        setLanguage(lang) {
            if (lang === undefined) return
            this.user.language = lang
            this.$store.commit('SET_CURRENT_USER', this.user)
            this.lang = lang
            this.$vuetify.lang.current = lang
            numbro.setLanguage(lang === 'ja' ? 'ja-JP' : 'en-US')
            storage.local.setItem('app.language', lang)
            setMapOptions({ language: lang })
        },

        changeLanguage(lang) {
            if (lang === this.lang) {
                return
            }

            this.setLanguage(lang)
            if (this.is_logged_in) {
                this.$store.dispatch('updateLanguage', this.user.language)
            }

            // router.push(
            //     lang == 'en'
            //         ? '/en' + router.currentRoute.path
            //         : router.currentRoute.path.replace(/^\/en/, '')
            // )
        },

        addTokenHook() {
            window.addEventListener(
                'storage',
                e => {
                    if (e.key == ACCESS_TOKEN_KEY) {
                        if (e.newValue === null) {
                            this._clearSession()
                        } else {
                            this.$store
                                .dispatch('getUser')
                                .then(() => {
                                    if (router.currentRoute.path !== '/') router.push('/')
                                })
                                .catch(this._handleError)
                        }
                    }

                    // reload window on storage message
                    if (e.key == 'kinchaku-communication-channel') {
                        try {
                            var message = JSON.parse(e.newValue)
                            if (!message) return // ignore empty msg or msg reset

                            // here you act on messages.
                            // you can send objects like { 'command': 'doit', 'data': 'abcd' }
                            if (message.command == 'switch-context') window.location = '/'
                            // other commands here
                        } catch (e) {
                            // do nothing
                        }
                    }
                },
                false
            )
        },

        _clearSession() {
            this.$store.dispatch('clearSession')
            router.push('/signin')
        },

        echoJoin(channel) {
            if (import.meta.env.VITE_ENV === 'local') return false
            return this.$echo.join(channel)
        },
        echoLeave(channel) {
            if (import.meta.env.VITE_ENV === 'local') return false
            return this.$echo.leave(channel)
        },
        // display error page instead of <router-view>
        showErrorPage(code = 500, message, error) {
            this.$store.commit('SET_ERROR_PAGE', { code, message, error })
        },
        // Display error page on intial loading error.
        handlePageLoadError(error) {
            // show either 404 or 500 page
            if (error == 403 || error?.status == 403 || error?.response?.status == 403) {
                this.showErrorPage('403')
            } else if (error == 404 || error?.status == 404 || error?.response?.status == 404) {
                this.showErrorPage('404')
            } else if (error == 500 || error?.status == 500 || error?.response?.status == 500) {
                this.showErrorPage('500', (error && error.message) || null, error)
            } else if (error == 503 || error?.status == 503 || error?.response?.status == 503) {
                this.showErrorPage('503')
            } else {
                // eslint-disable-next-line
                console.error(error)
            }
        },
        checkRouteSlashes() {
            if (window.location.pathname.startsWith('//')) {
                window.stop()
                window.location.pathname = '/404'
            }
        },
    },
    render: h => h(App),
})

// If running inside Cypress
if (window.Cypress) {
    // Attach the app to the window, which can be useful
    // for manually setting state in Cypress commands
    // such as `cy.logIn()`
    window.__app__ = app
}
